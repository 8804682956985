import { createContext, useContext } from "react";

const UploadContext = createContext();

export function useUploadContext() {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error(`useUploadContext must be used within a UploadContext`);
  }
  return context;
}

export default UploadContext;
