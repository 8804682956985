import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import InfoIcon from "@mui/icons-material/Info";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Typography
} from "@mui/material";

import React, { useCallback, useMemo, useState } from "react";

import { useAppContext } from "../AppContext";
import { round } from "../utils/helper";

export default function MenuBar({
  deleteItem,
  duplicateItem,
  setInch,
  setWireframe,
  setBoundingBox,
  centerCamera,
  wireframe,
  boundingBox,
  bx,
  by,
  bz,
}) {
  const { item, client, status } = useAppContext();
  const [infoAnchorEl, setInfoAnchorEl] = useState(null);

  const handleInfoOpen = useCallback((event) => {
    setInfoAnchorEl(event.currentTarget);
  }, []);

  const handleInfoClose = useCallback(() => {
    setInfoAnchorEl(null);
  }, []);

  const btnDisabled = useMemo(() => {
    if (!item || !item.geometry) return true;
    return false;
  }, [item]);

  const infoOpen = Boolean(infoAnchorEl);

  return (
    <Card sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ px: 1, display: "flex", justifyContent: "space-between" }}>
        {/* {client.enableInch && (
          <Tooltip title="Convert mm to inch" placement="top" arrow>
            <Switch
              checked={client.inch}
              onChange={(e) => setInch(e.target.checked)}
            />
          </Tooltip>
        )} */}
        {client.enableBoundingBox && (
          <>
            <IconButton
              disabled={btnDisabled}
              color="primary"
              aria-owns={infoOpen ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handleInfoOpen}
              onMouseLeave={handleInfoClose}
              component="span"
            >
              <InfoIcon />
            </IconButton>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={infoOpen}
              anchorEl={infoAnchorEl}
              onClose={handleInfoClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              disableRestoreFocus
            >
              <Box sx={{ p: 2 }}>
                <Typography variant="body1">Model info</Typography>
                <Divider sx={{ my: 1 }} />
                <Typography component="div" variant="caption">
                  Bounding box:
                  {item.useInch &&
                    round(bx * 25,4) +
                      " x " +
                      round(by * 25,4) +
                      " x " +
                      round(bz * 25,4) +
                      " in"}
                  {!item.useInch && bx + " x " + by + " x " + bz + " mm"}
                </Typography>

                <Typography component="div" variant="caption">
                  Volume:
                  {item.useInch && item.volume * 16387.1 + " in³"}
                  {!item.useInch && item.volume + " mm³"}
                </Typography>
              </Box>
            </Popover>
          </>
        )}
        {client.controlDuplicate && (
          <Tooltip title="Duplicate model" placement="top" arrow>
            <IconButton
              disabled={btnDisabled || status > 0}
              color="primary"
              onClick={duplicateItem}
              component="span"
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        )}
        {client.controlWireframe && (
          <Tooltip
            title={wireframe ? "Disable wireframe" : "Enable wireframe"}
            placement="top"
            arrow
          >
            <IconButton
              disabled={btnDisabled}
              color="primary"
              onClick={() => setWireframe(!wireframe)}
              component="span"
            >
              <FilterFramesIcon />
            </IconButton>
          </Tooltip>
        )}
        {client.controlPrintSpace && (
          <Tooltip
            title={boundingBox ? "Disable print space" : "Enable print space"}
            placement="top"
            arrow
          >
            <IconButton
              disabled={btnDisabled}
              color="primary"
              onClick={() => setBoundingBox(!boundingBox)}
              component="span"
            >
              <ViewInArIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Center camera" placement="top" arrow>
          <IconButton
            disabled={btnDisabled}
            color="primary"
            onClick={() => centerCamera()}
            component="span"
          >
            <ControlCameraIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete model" placement="top" arrow>
          <IconButton
            disabled={btnDisabled || status > 0}
            color="primary"
            onClick={() => deleteItem(item)}
            component="span"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
}
