import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Typography,
} from "@mui/material";

import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Canvas, useLoader } from "@react-three/fiber";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as THREE from "three";
import { Vector3 } from "three";

import { useAppContext } from "../AppContext";

import BoundingBox from "../utils/BoundingBox";
import { calculateBoundingBox } from "../utils/helper";

import useIsQuoter from "../hook/useIsQuoter";
import request from "../utils/request";
import MenuBar from "./MenuBar";

const inchMultiplier = 25.4;

const getScale = (size, buildPlate) => {
  let x = buildPlate[0] / size[0];
  let y = buildPlate[1] / size[2];
  let z = buildPlate[2] / size[1];

  let scale = Math.min(x, y, z);
  if (scale > 1) scale = 1;
  return scale;
};

const Viewer = (props) => {
  const {
    client,
    quoterId,
    items,
    item,
    color,
    status,
    setItems,
    setItem,
    updateItem,
    buildPlate,
    deleteItem,
  } = useAppContext();
  const { isBasic } = useIsQuoter();

  const [scale, setScale] = useState(1);
  const [bx, setBx] = useState(0);
  const [by, setBy] = useState(0);
  const [bz, setBz] = useState(0);
  const [cameraPosition, setCameraPosition] = useState([50, 100, 0]);
  const [objectPosition, setObjectPosition] = useState([0, 0, 0]);
  const [targetPoint, setTargetPoint] = useState([0, 0, 0]);
  const [wireframe, setWireframe] = useState(false);
  const [boundingBox, setBoundingBox] = useState(true);

  const objColor = useMemo(() => {
    if (!item) return { hex: "#ffffff", transparency: 0 };

    if (isBasic) {
      const c = client.colors.find((c) => c._id === color);
      return c;
    }

    const foundProcess = client.processes.find((p) => p._id === item.process);
    if (!foundProcess) return { hex: "#ffffff", transparency: 0 };

    const foundMaterialCategory = foundProcess.materialCategories.find(
      (m) => m._id === item.materialCategory
    );
    if (!foundMaterialCategory) return { hex: "#ffffff", transparency: 0 };

    const foundColor = foundMaterialCategory.colors.find(
      (c) => c._id === color
    );
    if (!foundColor) return { hex: "#ffffff", transparency: 0 };

    return foundColor;
  }, [item, color]);

  const cameraRef = useRef();
  const controlRef = useRef();
  const meshRef = useRef();

  let floorStyle = "chessboard.jpg";
  switch (client.floorStyle) {
    case "chessboard-colorless":
      floorStyle = "chessboard-colorless.png";
      break;
    case "white":
      floorStyle = "white.jpg";
      break;
    case "black":
      floorStyle = "black.jpg";
      break;
    case "transparent":
      floorStyle = "transparent.png";
      break;
    default:
      floorStyle = "chessboard.jpg";
  }

  const colorMap = useLoader(
    THREE.TextureLoader,
    process.env.REACT_APP_API_PATH + "/../static/" + floorStyle
  );
  colorMap.repeat.set(10, 10);
  colorMap.wrapS = colorMap.wrapT = THREE.RepeatWrapping;

  const calcBB = (geometry) => {
    let b = calculateBoundingBox(geometry);
    let scale = getScale(b, buildPlate);

    setScale(scale);
    setBx(b[0]);
    setBy(b[1]);
    setBz(b[2]);
  };

  const centerCamera = useCallback(() => {
    controlRef.current.reset();

    let v = new Vector3(0, 0, 0);
    let center = new Vector3(0, 0, 0);
    let size = 0;
    if (item.geometry.boundingBox) {
      center = item.geometry.boundingBox.getCenter(v);
      size = item.geometry.boundingBox.max.z - item.geometry.boundingBox.min.z;
    } else if (item.geometry.boundingSphere) {
      center = item.geometry.boundingSphere.center;
    }

    let mult = 1;
    if (item.useInch) {
      size = size * inchMultiplier;
      center.x = center.x * inchMultiplier;
      center.y = center.y * inchMultiplier;
      center.z = center.z * inchMultiplier;
      mult = inchMultiplier;
    }
    console.log(item);
    console.log([(bx * 2.5 + 10) * mult, by * mult, 0]);
    setCameraPosition([(bx * 2.5 + 10) * mult, by * mult, 0]);
    setTargetPoint([0, (size / 2) * scale, 0]);

    setObjectPosition([
      -center.x * scale,
      (size / 2) * scale - center.z * scale,
      center.y * scale,
    ]);
    //center.z*scale+size/2*scale
    //onCameraChanged();
  }, [bx, by, scale, item]);

  console.log(cameraRef.current?.position);

  useEffect(() => {
    if (!item) return;
    if (!item.geometry) return;
    if (!item.geometry.boundingBox && !item.geometry.boundingSphere) return;

    console.log("item changed");
    let v = new Vector3(0, 0, 0);
    let center = new Vector3(0, 0, 0);
    let size = 0;
    if (item.geometry.boundingBox) {
      center = item.geometry.boundingBox.getCenter(v);
      size = item.geometry.boundingBox.max.z - item.geometry.boundingBox.min.z;
    } else if (item.geometry.boundingSphere) {
      center = item.geometry.boundingSphere.center;
    }

    let mult = 1;
    if (item.useInch) {
      // size = size * 0.0393701;
      // center.x = center.x * 0.0393701;
      // center.y = center.y * 0.0393701;
      // center.z = center.z * 0.0393701;
      // mult = 0.0393701;
    }

    setObjectPosition([
      -center.x * scale,
      (size / 2) * scale - center.z * scale,
      center.y * scale,
    ]);
    if (!item.cameraPosition) {
      console.log("no position");
      setCameraPosition([(bx * 2.5 + 10) * mult, by * mult, 0]);
      setTargetPoint([0, (size / 2) * scale, 0]);
    } else {
      console.log("position");
      setCameraPosition([
        item.cameraPosition.x,
        item.cameraPosition.y,
        item.cameraPosition.z,
      ]);
      setTargetPoint(item.cameraTarget);
    }
    console.log(item);
  }, [bx, by, bz, scale, item]);

  const onCameraChanged = () => {
    const id = item._id;
    setItem((oldItem) => {
      const newItem = oldItem;
      newItem.cameraPosition = cameraRef.current.position.clone();
      newItem.cameraTarget = controlRef.current.target.clone();
      return newItem;
    });
    setItems((oldItems) => {
      const newItems = oldItems.map((i) => {
        if (i._id === id) {
          i.cameraPosition = cameraRef.current.position.clone();
          i.cameraTarget = controlRef.current.target.clone();
        }

        return i;
      });
      return newItems;
    });
    //props.setCameraPosition(cameraRef.current.position, controlRef.current.target);
  };

  const duplicateItem = async () => {
    if (!item) return;
    const newItem = { ...item, index: items.length };
    const geometry = newItem.geometry;
    delete newItem.geometry;
    const json = await request(
      "/request/copy/" + newItem.requestId,
      { ...newItem, quoterId: quoterId },
      {}
    );
    if (json.r == 1) {
      newItem.geometry = geometry;
      newItem._id = json.id;
      if (!newItem.cameraPosition) {
        newItem.cameraPosition = cameraRef.current.position.clone();
      } else {
        newItem.cameraPosition = newItem.cameraPosition.clone();
      }
      if (!newItem.cameraTarget) {
        newItem.cameraTarget = controlRef.current.target.clone();
      } else {
        newItem.cameraTarget = newItem.cameraTarget.clone();
      }

      setItem(newItem);
      setItems((g) => {
        return [...g, newItem];
      });
    }
  };

  const setInch = (useInch) => {
    setItem((oldItem) => {
      const newItem = { ...oldItem };
      newItem.useInch = useInch;
      newItem.cameraPosition = null;
      newItem.cameraTarget = null;
      console.log(newItem);
      return newItem;
    });

    setItems((oldItems) => {
      const newItems = oldItems.map((i) => {
        if (i._id === item._id) {
          i.useInch = useInch;
        }

        return i;
      });
      return newItems;
    });
  };

  const { geometry } = item || {};
  useEffect(() => {
    if (!item || !item.geometry) return;

    const geometry = item.geometry;
    calcBB(geometry);
  }, [geometry]);

  if (!item) return null;

  const loading = (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        zIndex: 999,
      }}
    >
      {item.status == 0 && (
        <>
          <FacebookCircularProgress />
          <Typography variant="h4" sx={{ mt: 2 }} color="black">
            Processing file...
          </Typography>
        </>
      )}
      {item.status == 10 && (
        <>
          <Typography variant="h4" color="red">
            Error
          </Typography>
          <Typography variant="body1" color="black">
            {item.message}
          </Typography>
        </>
      )}
    </Box>
  );

  const pointLightIntensity = 22000;
  return (
    <Box
      id="calculator-viewer"
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ flexGrow: 1, position: "relative", mb: 2, maxHeight: "90%" }}>
        {(!item.geometry || item.status == 10) && loading}
        {item.geometry && (
          <Canvas flat={true}>
            <PerspectiveCamera
              position={cameraPosition}
              rotation={[0, 1, 0]}
              ref={cameraRef}
              makeDefault
              far={10000}
            />
            <OrbitControls
              ref={controlRef}
              target={targetPoint}
              onEnd={onCameraChanged}
              camera={cameraRef.current}
              enableDamping={false}
            />

            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 10]} intensity={1.5} />
            <directionalLight position={[-10, 10, -10]} intensity={0.5} />

            {/* <directionalLight position={[0, buildPlate[1], 0]} intensity={0.5} />
            <pointLight position={[buildPlate[0], buildPlate[1]/2, 0]} intensity={pointLightIntensity} />
            <pointLight position={[0, buildPlate[1], buildPlate[2]]} intensity={pointLightIntensity} />
            <pointLight position={[0, buildPlate[1], -buildPlate[2]]} intensity={pointLightIntensity} />
            <pointLight position={[-buildPlate[0], buildPlate[1]/2, 0]} intensity={pointLightIntensity} />
            <pointLight position={[0, -buildPlate[1]/2, 0]} intensity={pointLightIntensity} /> */}
            {/* <ambientLight intensity={0.1} /> */}

            {/* <directionalLight
                            position={[-125, 0, -125]}
                            intensity={0.5}
                        /> */}
            <Model
              ref={meshRef}
              position={objectPosition}
              scale={scale}
              wireframe={wireframe}
              color={objColor}
              geometry={item.geometry}
              useInch={item.useInch}
              rotation={[Math.PI / -2, 0, 0]}
            />
            {boundingBox && <BoundingBox color="#000000" size={buildPlate} />}
            <mesh rotation={[-Math.PI / 2, 0, 0]}>
              <planeGeometry
                attach="geometry"
                args={[buildPlate[0], buildPlate[2]]}
              />
              <meshPhongMaterial
                transparent={true}
                map={colorMap}
                attach="material"
              />
            </mesh>
          </Canvas>
        )}
        {scale != 1 && (
          <Box sx={{ position: "absolute", bottom: 10, left: 10, zIndex: 999 }}>
            <Typography variant="subtitle1" color="black">
              Scale factor {Math.round(scale * 100) / 100}
            </Typography>
          </Box>
        )}
        {status > 0 && (
          <Box sx={{ position: "absolute", bottom: 10, left: 10, zIndex: 999 }}>
            <Typography variant="subtitle1" color="black">
              Request status:
              {status == 1 && " submitted"}
              {status == 2 && " in progress"}
              {status == 3 || (status == 4 && " closed")}
              {isNaN(status) && " submitted"}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <MenuBar
          deleteItem={deleteItem}
          duplicateItem={duplicateItem}
          setInch={setInch}
          setWireframe={setWireframe}
          setBoundingBox={setBoundingBox}
          centerCamera={centerCamera}
          wireframe={wireframe}
          boundingBox={boundingBox}
          bx={bx}
          by={by}
          bz={bz}
        />
      </Box>
    </Box>
  );
};

const Model = forwardRef((props, ref) => {
  const opacity = useMemo(() => {
    if (props.color.transparency === undefined) return 1;
    return (100 - props.color.transparency) / 100;
  }, [props.color.transparency]);

  const scale = useMemo(() => {
    console.log(props.useInch);
    if (props.useInch) {
      return inchMultiplier;
    }
    return 1;
  }, [props.useInch]);

  return (
    <mesh
      position={props.position}
      {...props}
      ref={ref}
      scale={[scale, scale, scale]}
    >
      <meshStandardMaterial
        wireframe={props.wireframe}
        color={props.color.hex}
        opacity={opacity}
        transparent
        metalness={0.3}
        specular={0x111111}
        // shininess={30}
        roughness={0.65}
      />
    </mesh>
  );
});

const FacebookCircularProgress = (props) => {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={80}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={80}
        thickness={4}
        {...props}
      />
    </Box>
  );
};

export default Viewer;
