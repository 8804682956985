import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useCallback, useMemo } from "react";
import { useAppContext } from "../AppContext";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function Fields({
  fields,
  disabled,
  changeFields,
  deleteAttachment,
  values = {},
}) {
  const { item } = useAppContext();
  
  const getValue = useCallback(
    (field) => {
      let value = "";
      const multiTypes = ["checkbox", "select"];
      const itemProps = ["color", "quantity"];
      if (multiTypes.includes(field.type)) {
        value = [];
      }
      if (itemProps.includes(field.type) && item[field.type]) {
        value = item[field.type];
      } else if (values && values[field.name]) {
        value = values[field.name];
      }

      return value;
    },
    [values]
  );

  if (!fields || !item) return null;

  return (
    <div>
      {fields.map((field, index) => {
        let value = getValue(field);

        //console.log(value);
        return (
          <Field
            key={index}
            field={field}
            disabled={disabled}
            changeField={changeFields}
            deleteAttachment={deleteAttachment}
            value={value}
          />
        );
      })}
    </div>
  );
}

// field types: text, number, date, select, checkbox, radio, textarea, file

function Field({ field, value, disabled, changeField, deleteAttachment }) {
  switch (field.type) {
    case "color":
      return (
        <Box sx={{ mb: 1 }}>
          <ColorInput
            field={field}
            disabled={disabled}
            value={value}
            changeField={changeField}
          />
        </Box>
      );
    case "quantity":
      return (
        <Box sx={{ mb: 1 }}>
          <QuantityInput
            field={field}
            disabled={disabled}
            value={value}
            changeField={changeField}
          />
        </Box>
      );
    case "text":
      return (
        <Box sx={{ mb: 1 }}>
          <TextInput
            field={field}
            disabled={disabled}
            value={value}
            changeField={changeField}
          />
        </Box>
      );
    case "number":
      return (
        <Box sx={{ mb: 1 }}>
          <NumberInput
            field={field}
            disabled={disabled}
            value={value}
            changeField={changeField}
          />
        </Box>
      );
    case "date":
      return (
        <Box sx={{ mb: 1 }}>
          <DateInput
            field={field}
            disabled={disabled}
            value={value}
            changeField={changeField}
          />
        </Box>
      );
    case "select":
      return (
        <Box sx={{ mb: 1 }}>
          <SelectInput
            field={field}
            disabled={disabled}
            value={value}
            changeField={changeField}
          />
        </Box>
      );
    case "checkbox":
      return (
        <Box sx={{ mb: 1 }}>
          <CheckboxInput
            field={field}
            disabled={disabled}
            value={value}
            changeField={changeField}
          />
        </Box>
      );
    case "radio":
      return (
        <Box sx={{ mb: 1 }}>
          <RadioInput
            field={field}
            disabled={disabled}
            value={value}
            changeField={changeField}
          />
        </Box>
      );
    case "textarea":
      return (
        <Box sx={{ mb: 1 }}>
          <TextareaInput
            field={field}
            disabled={disabled}
            value={value}
            changeField={changeField}
          />
        </Box>
      );
    case "file":
      return (
        <Box sx={{ mb: 1 }}>
          <FileInput
            field={field}
            disabled={disabled}
            value={value}
            changeField={changeField}
            deleteAttachment={deleteAttachment}
          />
        </Box>
      );
    default:
      return <Box sx={{ mb: 1 }}>Unknown field type</Box>;
  }
}

function InputWrapper({ field, children }) {
  return (
    <Box sx={{ mb: 1 }}>
      <FormControl variant="filled" fullWidth>
        <InputLabel shrink id={field.name + "-label"}>
          {field.label}
        </InputLabel>
      </FormControl>
      <Box sx={{ mx: 2 }}>{children}</Box>
    </Box>
  );
}

function ColorInput({ field, disabled, value, changeField }) {
  const theme = useTheme();

  const { client } = useAppContext();
  const colorList = useMemo(() => {
    return field.value.map((colId, index) => {
      const col = client.colors.find((c) => c._id === colId);

      let cn = "";
      let sx = {
        height: "28px",
        aspectRatio: "1",
        border:
          "1px solid " + (theme.palette.mode === "dark" ? "#aaa" : "#000"),
        bgcolor: col.hex,
        display: "inline-block",
        borderRadius: "50px",
        opacity: 0.25,
      };

      if (col._id === value) {
        sx.opacity = 1;
        if (disabled) sx.opacity = 0.5;
      }

      if (client.colorInfo) {
        return (
          <Tooltip
            title={
              <>
                <Typography variant="body1" color="inherit">
                  {col.name}
                </Typography>
                {(col.transparency && col.transparency) > 0 && (
                  <Typography variant="body2" color="inherit">
                    Transparency: {col.transparency}%
                  </Typography>
                )}
              </>
            }
            placement="top"
            arrow
            key={index}
          >
            <Box
              className={cn}
              onClick={(e) => changeField(field, colId)}
              sx={sx}
              info={col.name}
            ></Box>
          </Tooltip>
        );
      } else {
        return (
          <Box
            className={cn}
            onClick={(e) => changeField(field, colId)}
            sx={sx}
            info={col.name}
            key={index}
          ></Box>
        );
      }
    });
  }, [field, value, disabled, client]);

  return (
    <InputWrapper field={field}>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
        {colorList}
      </Box>
    </InputWrapper>
  );
}

function QuantityInput({ field, disabled, value, changeField }) {
  return (
    <NumberInput
      field={field}
      disabled={disabled}
      value={value}
      changeField={changeField}
    />
  );
}

function TextInput({ field, disabled, value, changeField }) {
  const inputProps = useMemo(() => {
    const props = {};
    if (field.min != "") {
      props.minlength = field.min;
    }
    if (field.max != "" && field.max != 0) {
      props.maxlength = field.max;
    }
    return props;
  }, [field]);


  return (
    <InputWrapper field={field}>
      <TextField
        disabled={disabled}
        id={field.name}
        variant="standard"
        size="small"
        fullWidth
        value={value}
        inputProps={inputProps}
        onBlur={(e) => changeField(field, e.target.value)}
        onChange={(e) => {
          changeField(field, e.target.value, false)
        }}
        sx={{ p: 0, pt: 1 }}
      />
    </InputWrapper>
  );
}

function NumberInput({ field, disabled, value, changeField }) {
  const inputProps = useMemo(() => {
    const props = {};
    if (field.min != "") {
      props.min = field.min;
    }
    if (field.max != "" && field.max != 0) {
      props.max = field.max;
    }
    if (field.step != "") {
      props.step = field.step;
    }
    return props;
  }, [field.min, field.max, field.step]);

  return (
    <InputWrapper field={field}>
      <TextField
        disabled={disabled}
        id={field.name}
        variant="standard"
        size="small"
        fullWidth
        value={value}
        inputProps={inputProps}
        onBlur={(e) => changeField(field, e.target.value)}
        onChange={(e) => changeField(field, e.target.value, false)}
        sx={{ p: 0, pt: 1 }}
        type="number"
      />
    </InputWrapper>
  );
}

function DateInput({ field, disabled, value, changeField }) {
  return (
    <InputWrapper field={field}>
      <TextField
        disabled={disabled}
        id={field.name}
        variant="standard"
        size="small"
        fullWidth
        value={value}
        onBlur={(e) => changeField(field, e.target.value)}
        onChange={(e) => changeField(field, e.target.value, false)}
        sx={{ p: 0, pt: 1 }}
        type="date"
      />
    </InputWrapper>
  );
}

function SelectInput({ field, disabled, value, changeField }) {
  return (
    <InputWrapper field={field}>
      <Select
        disabled={disabled}
        id={field.name}
        variant="standard"
        size="small"
        fullWidth
        value={value}
        multiple={field.multiple}
        onChange={(e) => {
          if (field.multiple) {
            if (value.indexOf(e.target.value) > -1) {
              changeField(
                field,
                value.filter((v) => v !== e.target.value)
              );
            } else {
              changeField(field, e.target.value);
            }
          } else {
            changeField(field, [e.target.value]);
          }
        }}
        renderValue={(selected) =>
          selected
            .map((s) => {
              //console.log(s);
              const found = field.options.find((c) => c.value == s);
              if (!found) return "";
              return found.label;
            })
            .filter((s) => {
              return s.toString().length > 0;
            })
            .join(", ")
        }
        sx={{ p: 0, pt: 1 }}
      >
        {field.options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              <Checkbox checked={value && value.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          );
        })}
      </Select>
    </InputWrapper>
  );
}

function CheckboxInput({ field, disabled, value, changeField }) {
  return (
    <InputWrapper field={field}>
      {field.options.map((option, index) => {
        return (
          <FormControlLabel
            key={index}
            control={
              <Switch
                id={field.name}
                disabled={disabled}
                checked={value.includes(option.value)}
                value={option.value}
                onChange={(e) => {
                  let newValue = value;
                  if (e.target.checked) {
                    newValue.push(e.target.value);
                  } else {
                    newValue = newValue.filter((v) => v !== e.target.value);
                  }
                  changeField(field, newValue);
                }}
                name={field.name}
              />
            }
            label={option.label}
          />
        );
      })}
    </InputWrapper>
  );
}

function RadioInput({ field, disabled, value, changeField }) {
  return (
    <InputWrapper field={field}>
      {field.options.map((option, index) => {
        return (
          <FormControlLabel
            key={index}
            control={
              <Switch
                id={field.name}
                disabled={disabled}
                checked={value === option.value}
                onChange={(e) => {
                  if (e.target.checked) {
                    return changeField(field, option.value);
                  }

                  changeField(field, false);
                }}
                name={field.name}
              />
            }
            label={option.label}
          />
        );
      })}
    </InputWrapper>
  );
}

function TextareaInput({ field, disabled, value, changeField }) {
  const inputProps = useMemo(() => {
    const props = {};
    if (field.min != "") {
      props.minlength = field.min;
    }
    if (field.max != "") {
      props.maxlength = field.max;
    }
    return props;
  }, [field.min, field.max]);

  return (
    <InputWrapper field={field}>
      <TextField
        disabled={disabled}
        id={field.name}
        variant="standard"
        size="small"
        fullWidth
        value={value}
        inputProps={inputProps}
        onBlur={(e) => changeField(field, e.target.value)}
        onChange={(e) => changeField(field, e.target.value, false)}
        sx={{ p: 0, pt: 1 }}
        multiline
        rows={field.rows || 3}
      />
    </InputWrapper>
  );
}

function FileInput({ field, disabled, value, changeField, deleteAttachment }) {
  return (
    <Box sx={{ mb: 1, mx: 2 }}>
      <Box sx={{ mb: 1 }}>
        {value.length > 0 && (
          <>
            <Typography variant="caption" color="textSecondary">
              {field.label}
            </Typography>
            <Box>
              {Object.keys(value).map((key, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {value[key].status == 0 && <CloudUploadIcon />}
                      {value[key].status == 1 && <AttachFileIcon />}
                      {value[key].status == 2 && <ErrorOutlineIcon />}
                      <Box sx={{ ml: 1 }}>
                        {value[key].name}
                        {value[key].status == 0 && (
                          <Typography
                            variant="caption"
                            color="info"
                            sx={{ display: "block" }}
                          >
                            Uploading...
                          </Typography>
                        )}
                        {value[key].status == 2 && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ display: "block" }}
                          >
                            {value[key].message}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <IconButton
                      onClick={() => {
                        deleteAttachment(field, value[key]);
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Box>
      <Button
        color="secondary"
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        fullWidth
        disabled={disabled}
        startIcon={<CloudUploadIcon />}
      >
        Upload files
        <VisuallyHiddenInput
          type="file"
          onChange={(e) => {
            if (field.multiple) {
              changeField(field, [...e.target.files]);
            } else {
              changeField(field, [e.target.files[0]]);
            }
          }}
          multiple={field.multiple}
        />
      </Button>
      <Box sx={{ mb: 1 }}>
        {field.maxSize && (
          <Box>
            <Typography variant="caption" color="textSecondary">
              Maximum file size: {field.maxSize} MB
            </Typography>
          </Box>
        )}
        {field.allowedTypes && (
          <Box>
            <Typography variant="caption" color="textSecondary">
              Allowed file types: {field.allowedTypes}
            </Typography>
          </Box>
        )}

        {field.forbiddenTypes && (
          <Box>
            <Typography variant="caption" color="textSecondary">
              Forbidden file types: {field.forbiddenTypes}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Fields;
