import { useState } from 'react';

import UploadContext from './UploadContext';

const UploadProvider = ({ children }) => {
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    
    const value = {
        uploading,
        setUploading,
        progress,
        setProgress,
    };
    
    return (
        <UploadContext.Provider value={value}>
        {children}
        </UploadContext.Provider>
    );
};

export default UploadProvider;